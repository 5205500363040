import translate from "components/translations/translations.wrapper";

export const LinkToHelpCenterReplacement = ({ translate }) => {
    const helpCenterReplacementUrl = typeof window.ENVIRONMENT !== "undefined" && window.ENVIRONMENT.HELP_CENTRE_REPLACEMENT_URL;
    return (
        <li className="icon" title={translate("menu.helpBoxReplacement.label")}>
            <a className="button-help text-transparent" target="tab" href={helpCenterReplacementUrl}>
                <span className="vismaicon vismaicon-menu vismaicon-dynamic vismaicon-help" />
                <span className="notificationBadge badge" />
                {translate("menu.helpBoxReplacement.label")}
            </a>
        </li>
    );
};

export default translate(LinkToHelpCenterReplacement);